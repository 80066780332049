import React from "react";
import Topbar from '../components/topbar';
import MainHeader from '../components/mainheader';
import StrickyHeader from '../components/strickyheader';

export default function Error(){
    return(
        <>
        <div class="page-wrapper">
            <Topbar />
            <MainHeader />
            <StrickyHeader />
            <section className="error-page">
                <div className="container">
                    <div className="error-page__content">
                    <div className="error-page__404"><img src="assets/images/shapes/404.png" alt="nisoz" /></div>{/* 404-image */}
                    <div className="error-page__shape1 wow slideInDown animated" data-wow-delay="300ms" style={{"visibility":"visible","-webkit-animation-delay":"300ms","animation-delay":"300ms","-webkit-animation-name":"_slideInDown","animation-name":"_slideInDown"}}><img src="assets/images/shapes/404-shape-1.png" alt="nisoz" /></div>{/* 404-image */}
                    <div className="error-page__shape2 wow slideInUp animated" data-wow-delay="400ms" style={{"visibility":"visible","-webkit-animation-delay":"400ms","animation-delay":"400ms","-webkit-animation-name":"_slideInUp","animation-name":"_slideInUp"}}><img src="assets/images/shapes/404-shape-2.png" alt="nisoz" /></div>{/* 404-image */}
                    <h4 className="error-page__title">Oops! Página não encontrada</h4>{/* 404-title */}
                    <p className="error-page__text">A página que você tentou acessar não existe</p>{/* 404-content */}
                    <a href="/" className="nisoz-btn">
                        <span className="nisoz-btn__text">Voltar para o início</span>
                    </a>{/* 404-btn */}
                    </div>{/* 404-info */}
                </div>
            </section>
        </div>
        </>
    )   
}