import React from "react";

export default function ChamadaOrcamento(){
        return( <section className="cta-three jarallax" data-jarallax data-speed="0.3" data-imgposition="50% -100%">
        <div className="cta-three__bg jarallax-img" style={{"background-image":"url(assets/images/backgrounds/cta-bg-3.jpg)"}}>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center wow fadeInUp" data-wow-delay="200ms">
              <div className="section-title text-center"> 
                <h2 className="section-title__title"> QUE TAL FAZER TEU <br />EVENTO COM QUEM ENTENDE </h2>
              </div>
              <a href="contact.html" className="nisoz-btn">
                <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                <span className="nisoz-btn__text">SOLICITE UM ORÇAMENTO</span>
              </a>
            </div>
          </div>
        </div>
      </section>
        )
}


