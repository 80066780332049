import React from "react";

export default function MobileNav(){
        return(
            <div className="mobile-nav__wrapper">
                <div className="mobile-nav__overlay mobile-nav__toggler" /> 
                    <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times" /></span>
                        <div className="logo-box">
                            <a href="/" aria-label="logo image"><img src="assets/images/logo.png" width={96} height={34} alt="nisoz" /></a>
                        </div> 
                        <div className="mobile-nav__container">
                            <ul className="main-menu__list">
                                <li className="scrollToLink"><a href="/">Home</a></li>  
                                <li className="scrollToLink"><a href="empresa">Empresa</a></li>
                                <li className="scrollToLink"><a href="servicos">Serviços</a></li>
                                <li className="scrollToLink"><a href="orcamentos">Orçamentos</a></li>
                                <li className="scrollToLink"><a href="contato">Contato</a></li>
                                <li className="scrollToLink"><a href="https://formandos.operaprodutora.com.br/" target="_blank" rel="noreferrer">Formandos</a></li>
                            </ul>
                        </div> 
                        <ul className="mobile-nav__contact list-unstyled">
                            <li>
                                <i className="fas fa-envelope" />
                                <a href="mailto:contato@operaprodutora.com.br">contato@operaprodutora.com.br</a>
                            </li>
                            <li>
                                <i className="fa fa-phone-alt" />
                                <a href="https://api.whatsapp.com/send?phone=5551997337941&text=Tenho%20interesse%20em%20um%20or%C3%A7amento:%0AFaculdade:%20%0ACurso:%0AQuantidade%20de%20formandos:%0AAno:%0ASemestre:%0A" target="_blank" rel="noreferrer">51 99733-7941</a>
                            </li>
                        </ul>
                        <div className="mobile-nav__social">
                            <a href="https://vimeo.com/operaprodutora"><i className="fab fa-vimeo" /></a>
                            <a href="https://www.facebook.com/OperaProdutora" target="_blank" rel="noreferrer"><i className="fab fa-facebook" /></a> 
                            <a href="https://www.instagram.com/operaprodutora/" target="_blank" rel="noreferrer"><i className="fab fa-instagram" /></a>
                        </div>
                    </div> 
                </div>
        )
}