import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Home from "./pages/home";
import Empresa from "./pages/empresa";
import Error from "./pages/error";
import Servicos from "./pages/servicos";
import Orcamentos from "./pages/orcamentos";
import Contato from "./pages/contato";
import Apresentacao from "./pages/apresentacao";

export default function App() {

  return (
    <BrowserRouter>
      <Helmet>
        <script src="assets/js/nisoz.js" type="text/javascript" />
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Apresentacao />}></Route>
        <Route exact path="/home" element={<Home />}></Route>
        <Route exact path="/empresa" element={<Empresa />}></Route>
        <Route exact path="/servicos" element={<Servicos />}></Route>
        <Route exact path="/orcamentos" element={<Orcamentos />}></Route>
        <Route exact path="/contato" element={<Contato />}></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
}