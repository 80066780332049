import React from "react";

export default function Video(){
        return(
            <section className="video-one">
            <div className="container">
              <div className="video-one__banner wow fadeInUp animated" data-wow-delay="100ms" style={{"visibility":"visible","-webkit-animation-delay":"100ms","animation-delay":"100ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                <img src="assets/images/backgrounds/video-bg-1-1.jpg" alt="nisoz" />
                <div className="video-one__border-wrap wow fadeInLeft animated" data-wow-delay="300ms" style={{"visibility":"visible","-webkit-animation-delay":"300ms","animation-delay":"300ms","-webkit-animation-name":"_fadeInLeft","animation-name":"_fadeInLeft"}}>
                  <div className="video-one__border-one" />
                  <div className="video-one__border-two" />
                </div> 
                <iframe src="https://player.vimeo.com/video/396041805?h=aec8d20f9c&title=0&byline=0&portrait=0" style={{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","padding":"50px"}} frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
              </div>
            </div>
          </section>
        )
}


