import React from "react";
import Topbar from "../components/topbar";
import MainHeader from "../components/mainheader";
import StrickyHeader from "../components/strickyheader";
import MobileNav from "../components/mobilenav";
import GoogleMap from "../components/googlemap";
import PageHeader from "../components/pageheader";
import MainFooter from "../components/mainfooter";

export default function Contato(){
    return(
        <>
        <div class="page-wrapper">
            <Topbar />
            <MainHeader />
            <StrickyHeader />

            <PageHeader titulo='Contato' />

            <GoogleMap />

            <section className="contact">
            <div className="container">
                <div className="row">
                <div className="col-lg-4 col-md-12 wow fadeInLeft animated" data-wow-delay="200ms" style={{"visibility":"visible","-webkit-animation-delay":"200ms","animation-delay":"200ms","-webkit-animation-name":"_fadeInLeft","animation-name":"_fadeInLeft"}}>
                    <div className="faq-page__help">
                    <div className="faq-page__help__icon"><span className="icon-phone-call-1" /></div>
                    <h3 className="faq-page__help__title">Tu tem<br /> alguma dúvida?</h3>
                    <p className="faq-page__help__text">Ligue para nós<a href="tel:+9288009850">(51) 99733-7941</a></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="contact__box">
                    <div className="contact__box__icon"><span className="icon-mailbox" /></div>
                    <h5 className="contact__box__title">Ou escreva um e-mail</h5>
                    <p className="contact__box__text"><a href="mailto:needhelp@company.com">needhelp@company.com</a></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="contact__box contact__box-two">
                    <div className="contact__box__icon"><span className="icon-maps-and-flags" /></div>
                    <h5 className="contact__box__title">Visite nosso  escritório</h5>
                    <p className="contact__box__text">Barão de Santo Ângelo 118 - Centro - Canoas/RS</p>
                    </div>
                </div>
                </div>
            </div>
            </section>

        </div>

        <MobileNav /> 
        <MainFooter />
        </>
    )
}