import React from "react";

export default function PageHeader(props){
        const {titulo} = props;
        return(
            <section className="page-header">
            <div className="page-header__bg" />{/* /.page-header__bg */}
            <div className="page-header__shape1" />{/* /.page-header__shape1 */}
            <div className="page-header__shape2" />{/* /.page-header__shape1 */}
            <div className="page-header__shape3 wow slideInRight animated" data-wow-delay="300ms" style={{"visibility":"visible","-webkit-animation-delay":"300ms","animation-delay":"300ms","-webkit-animation-name":"_slideInRight","animation-name":"_slideInRight"}} />{/* /.page-header__shape3 */}
            <div className="container">
                <ul className="page-header__breadcrumb list-unstyled">
                <li><a href="/">Home</a></li>
                <li><span>{titulo}</span></li>
                </ul>{/* /.page-breadcrumb list-unstyled */}
                <h2 className="page-header__title">{titulo}</h2>{/* /.page-title */}
            </div>{/* /.container */}
            </section>
        )
}

