import React from "react";
import Topbar from "../components/topbar";
import MainHeader from "../components/mainheader";
import StrickyHeader from "../components/strickyheader";
import MobileNav from "../components/mobilenav";
import PageHeader from "../components/pageheader";
import MainFooter from "../components/mainfooter";

export default function Empresa(){
    return(
        <>
        <div class="page-wrapper">
            <Topbar />
            <MainHeader />
            <StrickyHeader />
            
            <PageHeader titulo='Empresa' />

            <section className="about-four">
            <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    <div className="about-four__thumb">
                    <div className="about-four__thumb__item wow fadeInUp animated" data-wow-delay="200ms" style={{"visibility":"visible","-webkit-animation-delay":"200ms","animation-delay":"200ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}><img src="assets/images/resources/about-4-1.jpg" alt="nisoz" /></div>
                    <div className="about-four__thumb__item wow fadeInUp animated" data-wow-delay="300ms" style={{"visibility":"visible","-webkit-animation-delay":"300ms","animation-delay":"300ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}><img src="assets/images/resources/about-4-2.jpg" alt="nisoz" /></div>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInRight animated" data-wow-delay="200ms" style={{"visibility":"visible","-webkit-animation-delay":"200ms","animation-delay":"200ms","-webkit-animation-name":"_fadeInRight","animation-name":"_fadeInRight"}}>
                    <div className="about-four__content">
                    <div className="section-title">
                        <div className="section-title__triangle">
                        <span className="section-title__triangle-left" />
                        <span className="section-title__triangle-right" />
                        </div>
                        <h5 className="section-title__tagline">about agency</h5>
                        <h2 className="section-title__title">get to know about the nisoz agency</h2>
                    </div>{/* section-title */}
                    <h3 className="about-four__content__heading">Proin est lacus, sagittis lobortis iaculise get.</h3>
                    <p className="about-four__content__text">
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form,
                        by injected humour, or randomised words which don't look even slightly believable.
                    </p>
                    <div className="about-one__progress">
                        <h4 className="about-one__progress--title">Website Designing</h4>
                        <div className="about-one__progress--bar">
                        <div className="about-one__progress--inner count-bar counted" data-percent="77%" style={{"width":"77%"}}>
                            <div className="about-one__progress--number count-text">77%</div>
                        </div>
                        </div>
                    </div>{/* /.skills-item */}
                    <a href="contact.html" className="nisoz-btn">
                        <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                        <span className="nisoz-btn__text">Discover More</span>
                    </a>{/* /.btn */}
                    </div>
                </div>
                </div>
            </div>
            </section>

        </div>

        <MobileNav /> 
        <MainFooter />
        </>
    )
}