import React from "react";

export default function Depoimentos(){
        return(
            <section className="testimonial-one" style={{"padding-top":"150px"}}>
                <div className="container">
                <div className="testimonial-one__carousel nisoz-owl__carousel owl-theme owl-carousel" data-owl-options="{
                &quot;items&quot;: 1,
                &quot;margin&quot;: 0,
                &quot;smartSpeed&quot;: 3000, 
                &quot;autoplay&quot;: true, 
                &quot;autoplayTimeout&quot;: 5000, 
                &quot;loop&quot;:true, 
                &quot;nav&quot;:true,
                &quot;dots&quot;:false,
                &quot;navText&quot;: [&quot;<span class=\&quot;icon-left-arrow\&quot;></span>&quot;,&quot;<span class=\&quot;icon-right-arrow\&quot;></span>&quot;]
                }">
                    {/* Testimonial Item */}
                    <div className="item">
                    <div className="testimonial-one__item">
                        <div className="testimonial-one__author">
                        <img src="assets/images/resources/testimonial-1-1.jpg" alt="nisoz" />
                        <div className="testimonial-one__icon"><span className="icon-quote" /></div>{/* testimonial-quote-icon */}
                        <div className="testimonial-one__border">
                            <svg xmlns="http://www.w3.org/2000/svg" width={21} height={290} viewBox="0 0 21 290">
                            <path className="cls-1" d="M761,4387h1v76.34l-20,24.11,20,26.24V4677h-1V4513.68l-20-26.16,20-24.1V4387Z" transform="translate(-741 -4387)" />
                            </svg>
                        </div>{/* svg-border */}
                        </div>{/* testimonial-author-thumb */}
                        <div className="testimonial-one__content">
                        <div className="testimonial-one__ratings">
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                        </div>{/* testimonial-ratings */}
                        <div className="testimonial-one__quote">
                            This is due to their excellent service, competitive pricing and customer support. It’s through refresing to get such a personal touch.
                            Duis aute irure dolor in reprehenderit esse simply free text available in the market.
                        </div>{/* testimonial-quote */}
                        <div className="testimonial-one__meta">
                            <h5 className="testimonial-one__title">aleesha michale</h5>
                            <span className="testimonial-one__designation">ceo &amp; co founder</span>
                        </div>{/* testimonial-meta */}
                        </div>{/* testimonial-content */}
                    </div>
                    </div>
                    {/* Testimonial Item */}
                    {/* Testimonial Item */}
                    <div className="item">
                    <div className="testimonial-one__item">
                        <div className="testimonial-one__author">
                        <img src="assets/images/resources/testimonial-1-2.jpg" alt="nisoz" />
                        <div className="testimonial-one__icon"><span className="icon-quote" /></div>{/* testimonial-quote-icon */}
                        <div className="testimonial-one__border">
                            <svg xmlns="http://www.w3.org/2000/svg" width={21} height={290} viewBox="0 0 21 290">
                            <path className="cls-1" d="M761,4387h1v76.34l-20,24.11,20,26.24V4677h-1V4513.68l-20-26.16,20-24.1V4387Z" transform="translate(-741 -4387)" />
                            </svg>
                        </div>{/* svg-border */}
                        </div>{/* testimonial-author-thumb */}
                        <div className="testimonial-one__content">
                        <div className="testimonial-one__ratings">
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                        </div>{/* testimonial-ratings */}
                        <div className="testimonial-one__quote">
                            This is due to their excellent service, competitive pricing and customer support. It’s through refresing to get such a personal touch.
                            Duis aute irure dolor in reprehenderit esse simply free text available in the market.
                        </div>{/* testimonial-quote */}
                        <div className="testimonial-one__meta">
                            <h5 className="testimonial-one__title">aleesha michale</h5>
                            <span className="testimonial-one__designation">ceo &amp; co founder</span>
                        </div>{/* testimonial-meta */}
                        </div>{/* testimonial-content */}
                    </div>
                    </div>
                    {/* Testimonial Item */}
                    {/* Testimonial Item */}
                    <div className="item">
                    <div className="testimonial-one__item">
                        <div className="testimonial-one__author">
                        <img src="assets/images/resources/testimonial-1-3.jpg" alt="nisoz" />
                        <div className="testimonial-one__icon"><span className="icon-quote" /></div>{/* testimonial-quote-icon */}
                        <div className="testimonial-one__border">
                            <svg xmlns="http://www.w3.org/2000/svg" width={21} height={290} viewBox="0 0 21 290">
                            <path className="cls-1" d="M761,4387h1v76.34l-20,24.11,20,26.24V4677h-1V4513.68l-20-26.16,20-24.1V4387Z" transform="translate(-741 -4387)" />
                            </svg>
                        </div>{/* svg-border */}
                        </div>{/* testimonial-author-thumb */}
                        <div className="testimonial-one__content">
                        <div className="testimonial-one__ratings">
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                        </div>{/* testimonial-ratings */}
                        <div className="testimonial-one__quote">
                            This is due to their excellent service, competitive pricing and customer support. It’s through refresing to get such a personal touch.
                            Duis aute irure dolor in reprehenderit esse simply free text available in the market.
                        </div>{/* testimonial-quote */}
                        <div className="testimonial-one__meta">
                            <h5 className="testimonial-one__title">aleesha michale</h5>
                            <span className="testimonial-one__designation">ceo &amp; co founder</span>
                        </div>{/* testimonial-meta */}
                        </div>{/* testimonial-content */}
                    </div>
                    </div>
                    {/* Testimonial Item */}
                </div>
                </div>
            </section>
        )
}


