import React from "react";
import Topbar from "../components/topbar";
import MainHeader from "../components/mainheader";
import StrickyHeader from "../components/strickyheader";
import MobileNav from "../components/mobilenav";
import PageHeader from "../components/pageheader";
import MainFooter from "../components/mainfooter";

export default function Servicos(){
    return(
        <>
        <div class="page-wrapper">
            <Topbar />
            <MainHeader />
            <StrickyHeader />
            
            <PageHeader titulo='Serviços' />

            <section className="service-two service-two-padding">
            <div className="container">
                <div className="row">
                <div className="col-lg-4 col-md-6 wow fadeInUp animated" data-wow-delay="100ms" style={{"visibility":"visible","-webkit-animation-delay":"100ms","animation-delay":"100ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                    <div className="service-two__item">
                    <div className="service-two__thumb">
                        <img src="assets/images/service/service-1-1.jpg" alt="nisoz" />
                    </div>
                    <div className="service-two__content">
                        <div className="service-two__item__hover" style={{"background-image":"url(assets/images/shapes/service-2-hover-shape.png)"}} />
                        <div className="service-two__item__icon">
                        <span className="icon-website-design" />
                        </div>{/* /.service-icon */}
                        <div className="service-two__item__number">Service</div>{/* /.service-number */}
                        <h3 className="service-two__item__title">
                        <a href="website-designing.html">Website Designing</a>
                        </h3>{/* /.service-title */}
                        <div className="service-two__item__border" />
                        <p className="service-two__item__text">Sed quia magni doles eos qui ration volupta tem sequi.</p>{/* /.service-content */}
                    </div>
                    </div>{/* /.service-card-one */}
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp animated" data-wow-delay="200ms" style={{"visibility":"visible","-webkit-animation-delay":"200ms","animation-delay":"200ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                    <div className="service-two__item">
                    <div className="service-two__thumb">
                        <img src="assets/images/service/service-1-2.jpg" alt="nisoz" />
                    </div>
                    <div className="service-two__content">
                        <div className="service-two__item__hover" style={{"background-image":"url(assets/images/shapes/service-2-hover-shape.png)"}} />
                        <div className="service-two__item__icon">
                        <span className="icon-website-development" />
                        </div>{/* /.service-icon */}
                        <div className="service-two__item__number">Service</div>{/* /.service-number */}
                        <h3 className="service-two__item__title">
                        <a href="website-development.html">Website Development</a>
                        </h3>{/* /.service-title */}
                        <div className="service-two__item__border" />
                        <p className="service-two__item__text">Sed quia magni doles eos qui ration volupta tem sequi.</p>{/* /.service-content */}
                    </div>
                    </div>{/* /.service-card-one */}
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp animated" data-wow-delay="300ms" style={{"visibility":"visible","-webkit-animation-delay":"300ms","animation-delay":"300ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                    <div className="service-two__item">
                    <div className="service-two__thumb">
                        <img src="assets/images/service/service-1-3.jpg" alt="nisoz" />
                    </div>
                    <div className="service-two__content">
                        <div className="service-two__item__hover" style={{"background-image":"url(assets/images/shapes/service-2-hover-shape.png)"}} />
                        <div className="service-two__item__icon">
                        <span className="icon-mobile-application" />
                        </div>{/* /.service-icon */}
                        <div className="service-two__item__number">Service</div>{/* /.service-number */}
                        <h3 className="service-two__item__title">
                        <a href="mobile-application.html">Mobile Application</a>
                        </h3>{/* /.service-title */}
                        <div className="service-two__item__border" />
                        <p className="service-two__item__text">Sed quia magni doles eos qui ration volupta tem sequi.</p>{/* /.service-content */}
                    </div>
                    </div>{/* /.service-card-one */}
                </div>
                </div>
            </div>
            </section>

        </div>

        <MobileNav /> 
        <MainFooter />
        </>
    )
}