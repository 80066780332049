import React from "react";

export default function MainFooter(){
        return(
            <footer className="main-footer">
                <div className="main-footer__bg" style={{"background-image":"url(assets/images/shapes/footer-bg-1.png)"}} />
                <div className="container">
                    <div className="main-footer__top wow" data-wow-delay="100ms" style={{"visibility":"visible","-webkit-animation-delay":"100ms","animation-delay":"100ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                        <a href="/" className="main-footer__logo">
                            <img src="assets/images/logo.png" alt="Opera Produtora" width={116} height={54} />
                        </a>
                        <div className="main-footer__social">
                            <a href="https://vimeo.com/operaprodutora"><i className="fab fa-vimeo" /></a>
                            <a href="https://www.facebook.com/OperaProdutora"><i className="fab fa-facebook" /></a>
                            <a href="https://www.instagram.com/operaprodutora/"><i className="fab fa-instagram" /></a>
                        </div> 
                    </div> 
                </div> 
                <div className="container wow  text-center" data-wow-delay="500ms" style={{"visibility":"visible","-webkit-animation-delay":"500ms","animation-delay":"500ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                    <p className="copyright__text">© Copyright <span className="dynamic-year">2023</span>{/* /.dynamic-year */} by <a href="/">Opera Produtora</a></p>
                </div>
            </footer>
        )
}