import React from "react";

export default function Sobre(){
        return(
            <section className="about-three">
                <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                    <div className="about-three__thumb wow fadeInLeft" data-wow-delay="300ms">
                        {/* about thumb start */}
                        <div className="about-three__thumb__one">
                        <img src="assets/images/resources/about-3-1.jpg" alt="nisoz" /> 
                        </div>
                        <div className="about-three__thumb__two nisoz-tilt" data-tilt-options="{ &quot;glare&quot;: false, &quot;maxGlare&quot;: 0, &quot;maxTilt&quot;: 5, &quot;speed&quot;: 700, &quot;scale&quot;: 1 }">
                        <img src="assets/images/resources/about-3-2.jpg" alt="nisoz" />
                        </div>
                    </div>{/* about thumb end */}
                    </div>
                    <div className="col-lg-5 wow fadeInRight" data-wow-delay="300ms">
                    <div className="about-three__content">
                        {/* about content start*/}
                        <div className="section-title">
                        <div className="section-title__triangle">
                            <span className="section-title__triangle-left" />
                            <span className="section-title__triangle-right" />
                        </div>
                        <h5 className="section-title__tagline">Conheça a Ópera Produtora</h5>
                        <h2 className="section-title__title">ESTEJA PRONTO PARA O EVENTO DA SUA VIDA</h2>
                        </div>{/* section-title */}
                        <p className="about-three__text">
                        There are many variations of simply free text passages of available but the majority have
                        suffered alteration
                        in some form, by injected hum randomised words which don't slightly.
                        </p> 
                        <ul className="about-three__lists">
                        <li><span className="fa fa-check-circle" />Item 1</li>
                        <li><span className="fa fa-check-circle" />Item 2</li>
                        <li><span className="fa fa-check-circle" />Item 3</li>
                        </ul>
                        <a href="about.html" className="nisoz-btn">
                        <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                        <span className="nisoz-btn__text">Ver mais</span>
                        </a>{/* /.btn */}
                    </div>{/* about content end*/}
                    </div>
                </div>
                </div>
            </section>  
        )
}


