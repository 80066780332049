import React from "react";

export default function GoogleMap(){
        return(
            <section className="google-map google-map-two">
                <div className="container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.520218785666!2d-51.18344712317977!3d-29.906922210514477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95197000111fac23%3A0x37d41626a60c8650!2sR.%20Bar%C3%A3o%20de%20Santo%20%C3%82ngelo%2C%20118%20-%20Centro%2C%20Canoas%20-%20RS%2C%2092010-140!5e0!3m2!1spt-BR!2sbr!4v1693781170715!5m2!1spt-BR!2sbr" width={600} height={450} style={{"border":"0"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" class="google-map__one" title="maps"/>
                </div>
            </section>
        )
}


