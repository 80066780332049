import React from "react";

export default function Topbar(){
        return(
            <section className="topbar">
                <div className="container-fluid">
                    <div className="topbar__wrapper">
                    <ul className="list-unstyled topbar__list">
                        <li>
                        <span className="fas fa-envelope" />
                        <a href="mailto:contato@operaprodutora.com.br">contato@operaprodutora.com.br</a>
                        </li>
                        <li>
                        <span className="fas fa-map-marker" />
                            Barão de Santo Ângelo 118 - Centro - Canoas/RS
                        </li>
                    </ul>
                    {/* 
                        <ul className="list-unstyled topbar__menu">
                            <li><a href="faq.html">Help</a></li>
                            <li><a href="about.html">Support</a></li>
                            <li><a href="contact.html">Contact</a></li>
                        </ul>
                    */}
                    <div className="topbar__social">
                        <a href="https://vimeo.com/operaprodutora"><i className="fab fa-vimeo" /></a>
                        <a href="https://www.facebook.com/OperaProdutora"><i className="fab fa-facebook" /></a> 
                        <a href="https://www.instagram.com/operaprodutora/"><i className="fab fa-instagram" /></a>
                    </div>
                    </div>
                </div>
            </section>
        )
}
