import React from "react";

export default function Servicos(){
        return(
            <section className="feature-three">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-delay="300ms">
                    <div className="section-title">
                        <div className="section-title__triangle">
                        <span className="section-title__triangle-left" />
                        <span className="section-title__triangle-right" />
                        </div>
                        <h5 className="section-title__tagline">Serviços</h5>
                        <h2 className="section-title__title">Conheça todos os sonhos<br /> que podemos relalizar</h2>
                    </div>{/* section-title */}
                    <h4 className="feature-three__heading">O melhor evento da sua vida é aqui</h4>
                    <p className="feature-three__text">
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form,
                        by injected humour, or randomised words which don't look even slightly believable.
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form,
                    </p>
                    <p className="feature-three__text">
                        by injected humour, or randomised words which don't look even slightly believable.
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form,
                        by injected humour, or randomised words which don't look even slightly believable.
                    </p>
                    {/*
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="feature-three__box">
                                        <div class="feature-three__box__icon"><span class="icon-growth"></span></div>
                                        <h5 class="feature-three__box__title">Market Management</h5>
                                        <p class="feature-three__box__text">Lorem ipsum is not tex dolor sit amet, consectetur
                                            notted.</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="feature-three__box">
                                        <div class="feature-three__box__icon"><span class="icon-increment"></span></div>
                                        <h5 class="feature-three__box__title">Business Analysis</h5>
                                        <p class="feature-three__box__text">Lorem ipsum is not tex dolor sit amet, consectetur
                                            notted.</p>
                                    </div>
                                </div>
                            </div>
                            */}
                    </div>
                    <div className="col-lg-6 wow fadeInRight" data-wow-delay="300ms">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="feature-three__circle nisoz-tilt" data-tilt-options="{ &quot;glare&quot;: false, &quot;maxGlare&quot;: 0, &quot;maxTilt&quot;: 20, &quot;speed&quot;: 700, &quot;scale&quot;: 1 }">
                            <h5 className="feature-three__circle__text">Seu sonho</h5>
                        </div>{/* circle-box */}
                        </div>
                        <div className="col-md-6">
                        <div className="feature-three__thumb">
                            <img src="assets/images/resources/feature-4.jpg" alt="nisoz" />
                        </div>{/* feature-image */}
                        </div>
                        <div className="col-md-6">
                        <div className="feature-three__thumb">
                            <img src="assets/images/resources/feature-5.jpg" alt="nisoz" />
                        </div>{/* feature-image */}
                        </div>
                        <div className="col-md-6">
                        <div className="feature-three__circle feature-three__circle-two nisoz-tilt" data-tilt-options="{ &quot;glare&quot;: false, &quot;maxGlare&quot;: 0, &quot;maxTilt&quot;: 20, &quot;speed&quot;: 700, &quot;scale&quot;: 1 }">
                            <h5 className="feature-three__circle__text">A gente realiza</h5>
                        </div>{/* circle-box */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section> 
        )
}


