import React from "react";

export default function Slider(){
        return(
            <section className="main-slider">
            <div className="main-slider__one nisoz-owl__carousel owl-carousel owl-loaded owl-drag" data-owl-options="{
                    &quot;loop&quot;: true,
                    &quot;animateOut&quot;: &quot;slideOutDown&quot;,
                    &quot;animateIn&quot;: &quot;fadeIn&quot;,
                    &quot;items&quot;: 1,
                    &quot;smartSpeed&quot;: 1000, 
            &quot;autoplay&quot;: true, 
            &quot;autoplayTimeout&quot;: 6000, 
                    &quot;autoplayHoverPause&quot;: true,
                    &quot;nav&quot;: false,
                    &quot;dots&quot;: true,
                    &quot;margin&quot;: 0
                    }">
                {/* slider item end */}
                {/* slider item end */}
                {/* slider item end */}
                <div className="owl-stage-outer"><div className="owl-stage" style={{"-webkit-transform":"translate3d(-2530px, 0px, 0px)","-ms-transform":"translate3d(-2530px, 0px, 0px)","transform":"translate3d(-2530px, 0px, 0px)","-webkit-transition":"all 0s ease 0s","transition":"all 0s ease 0s","width":"8855px"}}><div className="owl-item cloned" style={{"width":"1265px"}}><div className="item">{/* slider item start */}
                        <div className="main-slider__item">
                        <div className="main-slider__shape-1">
                            <img src="assets/images/shapes/slider-1-bg-1.png" alt="nisoz" />
                        </div>{/* shape-one */}
                        <div className="main-slider__shape-2">
                            <img src="assets/images/shapes/slider-1-shape-1.png" alt="nisoz" />
                        </div>{/* shape-two */}
                        <div className="main-slider__shape-3">
                            <img src="assets/images/backgrounds/slier-1-bg-2.jpg" alt="nisoz" />
                        </div>{/* shape-three */}
                        <div className="main-slider__shape-4">
                            <img src="assets/images/shapes/slider-1-shape-2.png" alt="nisoz" />
                        </div>{/* shape-four */}
                        <div className="container">
                            <div className="row">
                            <div className="col-xl-8">
                                <div className="main-slider__content">
                                <h2 className="main-slider__title">Creative <br />Services For <br />Your Business</h2>{/* slider-title */}
                                <p className="main-slider__text">We are delivering the best services to our customers.</p>{/* slider-text */}
                                <div className="main-slider__btn">
                                    <a href="about.html" className="nisoz-btn">
                                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                                    <span className="nisoz-btn__text">Discover More</span>
                                    </a>{/* /.btn */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div></div><div className="owl-item cloned" style={{"width":"1265px"}}><div className="item">{/* slider item start */}
                        <div className="main-slider__item">
                        <div className="main-slider__shape-1">
                            <img src="assets/images/shapes/slider-1-bg-1.png" alt="nisoz" />
                        </div>{/* shape-one */}
                        <div className="main-slider__shape-2">
                            <img src="assets/images/shapes/slider-1-shape-1.png" alt="nisoz" />
                        </div>{/* shape-two */}
                        <div className="main-slider__shape-3">
                            <img src="assets/images/backgrounds/slier-1-bg-3.jpg" alt="nisoz" />
                        </div>{/* shape-three */}
                        <div className="main-slider__shape-4">
                            <img src="assets/images/shapes/slider-1-shape-2.png" alt="nisoz" />
                        </div>{/* shape-four */}
                        <div className="container">
                            <div className="row">
                            <div className="col-xl-8">
                                <div className="main-slider__content">
                                <h2 className="main-slider__title">Creative <br />Services For <br />Your Business</h2>{/* slider-title */}
                                <p className="main-slider__text">We are delivering the best services to our customers.</p>{/* slider-text */}
                                <div className="main-slider__btn">
                                    <a href="about.html" className="nisoz-btn">
                                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                                    <span className="nisoz-btn__text">Discover More</span>
                                    </a>{/* /.btn */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div></div><div className="owl-item active" style={{"width":"1265px"}}><div className="item">{/* slider item start */}
                        <div className="main-slider__item">
                        <div className="main-slider__shape-1">
                            <img src="assets/images/shapes/slider-1-bg-1.png" alt="nisoz" />
                        </div>{/* shape-one */}
                        <div className="main-slider__shape-2">
                            <img src="assets/images/shapes/slider-1-shape-1.png" alt="nisoz" />
                        </div>{/* shape-two */}
                        <div className="main-slider__shape-3">
                            <img src="assets/images/backgrounds/slier-1-bg-1.jpg" alt="nisoz" />
                        </div>{/* shape-three */}
                        <div className="main-slider__shape-4">
                            <img src="assets/images/shapes/slider-1-shape-2.png" alt="nisoz" />
                        </div>{/* shape-four */}
                        <div className="container">
                            <div className="row">
                            <div className="col-xl-8">
                                <div className="main-slider__content">
                                <h2 className="main-slider__title">Creative <br />Services For <br />Your Business</h2>{/* slider-title */}
                                <p className="main-slider__text">We are delivering the best services to our customers.</p>{/* slider-text */}
                                <div className="main-slider__btn">
                                    <a href="about.html" className="nisoz-btn">
                                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                                    <span className="nisoz-btn__text">Discover More</span>
                                    </a>{/* /.btn */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div></div><div className="owl-item" style={{"width":"1265px"}}><div className="item">{/* slider item start */}
                        <div className="main-slider__item">
                        <div className="main-slider__shape-1">
                            <img src="assets/images/shapes/slider-1-bg-1.png" alt="nisoz" />
                        </div>{/* shape-one */}
                        <div className="main-slider__shape-2">
                            <img src="assets/images/shapes/slider-1-shape-1.png" alt="nisoz" />
                        </div>{/* shape-two */}
                        <div className="main-slider__shape-3">
                            <img src="assets/images/backgrounds/slier-1-bg-2.jpg" alt="nisoz" />
                        </div>{/* shape-three */}
                        <div className="main-slider__shape-4">
                            <img src="assets/images/shapes/slider-1-shape-2.png" alt="nisoz" />
                        </div>{/* shape-four */}
                        <div className="container">
                            <div className="row">
                            <div className="col-xl-8">
                                <div className="main-slider__content">
                                <h2 className="main-slider__title">Creative <br />Services For <br />Your Business</h2>{/* slider-title */}
                                <p className="main-slider__text">We are delivering the best services to our customers.</p>{/* slider-text */}
                                <div className="main-slider__btn">
                                    <a href="about.html" className="nisoz-btn">
                                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                                    <span className="nisoz-btn__text">Discover More</span>
                                    </a>{/* /.btn */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div></div><div className="owl-item" style={{"width":"1265px"}}><div className="item">{/* slider item start */}
                        <div className="main-slider__item">
                        <div className="main-slider__shape-1">
                            <img src="assets/images/shapes/slider-1-bg-1.png" alt="nisoz" />
                        </div>{/* shape-one */}
                        <div className="main-slider__shape-2">
                            <img src="assets/images/shapes/slider-1-shape-1.png" alt="nisoz" />
                        </div>{/* shape-two */}
                        <div className="main-slider__shape-3">
                            <img src="assets/images/backgrounds/slier-1-bg-3.jpg" alt="nisoz" />
                        </div>{/* shape-three */}
                        <div className="main-slider__shape-4">
                            <img src="assets/images/shapes/slider-1-shape-2.png" alt="nisoz" />
                        </div>{/* shape-four */}
                        <div className="container">
                            <div className="row">
                            <div className="col-xl-8">
                                <div className="main-slider__content">
                                <h2 className="main-slider__title">Creative <br />Services For <br />Your Business</h2>{/* slider-title */}
                                <p className="main-slider__text">We are delivering the best services to our customers.</p>{/* slider-text */}
                                <div className="main-slider__btn">
                                    <a href="about.html" className="nisoz-btn">
                                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                                    <span className="nisoz-btn__text">Discover More</span>
                                    </a>{/* /.btn */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div></div><div className="owl-item cloned" style={{"width":"1265px"}}><div className="item">{/* slider item start */}
                        <div className="main-slider__item">
                        <div className="main-slider__shape-1">
                            <img src="assets/images/shapes/slider-1-bg-1.png" alt="nisoz" />
                        </div>{/* shape-one */}
                        <div className="main-slider__shape-2">
                            <img src="assets/images/shapes/slider-1-shape-1.png" alt="nisoz" />
                        </div>{/* shape-two */}
                        <div className="main-slider__shape-3">
                            <img src="assets/images/backgrounds/slier-1-bg-1.jpg" alt="nisoz" />
                        </div>{/* shape-three */}
                        <div className="main-slider__shape-4">
                            <img src="assets/images/shapes/slider-1-shape-2.png" alt="nisoz" />
                        </div>{/* shape-four */}
                        <div className="container">
                            <div className="row">
                            <div className="col-xl-8">
                                <div className="main-slider__content">
                                <h2 className="main-slider__title">Creative <br />Services For <br />Your Business</h2>{/* slider-title */}
                                <p className="main-slider__text">We are delivering the best services to our customers.</p>{/* slider-text */}
                                <div className="main-slider__btn">
                                    <a href="about.html" className="nisoz-btn">
                                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                                    <span className="nisoz-btn__text">Discover More</span>
                                    </a>{/* /.btn */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div></div><div className="owl-item cloned" style={{"width":"1265px"}}><div className="item">{/* slider item start */}
                        <div className="main-slider__item">
                        <div className="main-slider__shape-1">
                            <img src="assets/images/shapes/slider-1-bg-1.png" alt="nisoz" />
                        </div>{/* shape-one */}
                        <div className="main-slider__shape-2">
                            <img src="assets/images/shapes/slider-1-shape-1.png" alt="nisoz" />
                        </div>{/* shape-two */}
                        <div className="main-slider__shape-3">
                            <img src="assets/images/backgrounds/slier-1-bg-2.jpg" alt="nisoz" />
                        </div>{/* shape-three */}
                        <div className="main-slider__shape-4">
                            <img src="assets/images/shapes/slider-1-shape-2.png" alt="nisoz" />
                        </div>{/* shape-four */}
                        <div className="container">
                            <div className="row">
                            <div className="col-xl-8">
                                <div className="main-slider__content">
                                <h2 className="main-slider__title">Creative <br />Services For <br />Your Business</h2>{/* slider-title */}
                                <p className="main-slider__text">We are delivering the best services to our customers.</p>{/* slider-text */}
                                <div className="main-slider__btn">
                                    <a href="about.html" className="nisoz-btn">
                                    <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                                    <span className="nisoz-btn__text">Discover More</span>
                                    </a>{/* /.btn */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div></div></div></div><div className="owl-nav disabled"><button type="button" role="presentation" className="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" className="owl-next"><span aria-label="Next">›</span></button></div><div className="owl-dots"><button role="button" className="owl-dot active"><span /></button><button role="button" className="owl-dot"><span /></button><button role="button" className="owl-dot"><span /></button></div></div>
            </section>
        )
}