import React from "react";

export default function Realizacoes(){
        return(
            <section className="fact-two">
            <div className="fact-two__bg" style={{"background-image":"url(assets/images/shapes/funfact-bg-2.png)"}} />
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="section-title">
                    <div className="section-title__triangle">
                      <span className="section-title__triangle-left" />
                      <span className="section-title__triangle-right" />
                    </div>
                    <h5 className="section-title__tagline">Realizações</h5>
                    <h2 className="section-title__title">Comprometimento do inicio ao fim, geram números marcantes</h2>
                  </div>{/* section-title */} 
                </div>
                <div className="col-lg-7 wow fadeInUp animated animated" data-wow-delay="100ms" style={{"visibility":"visible","-webkit-animation-delay":"100ms","animation-delay":"100ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                  <div className="fact-two__wrapper">
                    <div className="fact-two__item text-center">
                      <div className="fact-two__item__icon"><span className="icon-campaign" /></div>{/* /.fact-two__icon */}
                      <div className="fact-two__item__count">
                        <span className="count-box counted">
                          <span className="count-text" data-stop={886} data-speed={1500}>886</span>
                        </span>
                      </div>{/* /.fact-two__count */}
                      <h3 className="fact-two__item__title">Eventos Realizados</h3>{/* /.fact-two__title */}
                    </div>{/* /.fact-two__item */}
                    <div className="fact-two__item text-center">
                      <div className="fact-two__item__icon"><span className="icon-recommend" /></div>{/* /.fact-two__icon */}
                      <div className="fact-two__item__count">
                        <span className="count-box counted">
                          <span className="count-text" data-stop={601} data-speed={1500}>601</span>
                        </span>
                      </div>{/* /.fact-two__count */}
                      <h3 className="fact-two__item__title">Horas trabalhadas</h3>{/* /.fact-two__title */}
                    </div>{/* /.fact-two__item */}
                    <div className="fact-two__item text-center">
                      <div className="fact-two__item__icon"><span className="icon-increment" /></div>{/* /.fact-two__icon */}
                      <div className="fact-two__item__count">
                        <span className="count-box counted">
                          <span className="count-text" data-stop={960} data-speed={1500}>960</span>
                        </span>
                      </div>{/* /.fact-two__count */}
                      <h3 className="fact-two__item__title">Clientes Felizes</h3>{/* /.fact-two__title */}
                    </div>{/* /.fact-two__item */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
}


