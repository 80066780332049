import React from "react";

export default function MainHeader(){
        return(
            <header className="main-header">
                <nav className="main-menu">
                    <div className="container-fluid">
                        <div className="main-menu__logo">
                            <a href="/home">
                            <img src="assets/images/logo.png" width={126} height={64} alt="Opera Produtora" />
                            </a>
                        </div>
                        <div className="main-menu__nav">
                            <ul className="main-menu__list one-page-scroll-menu"> 
                                <li className="scrollToLink"><a href="/home">Home</a></li> 
                                <li className="scrollToLink"><a href="empresa">Empresa</a></li>
                                <li className="scrollToLink"><a href="servicos">Serviços</a></li>
                                <li className="scrollToLink"><a href="orcamentos">Orçamentos</a></li>
                                <li className="scrollToLink"><a href="Contato">Contato</a></li>
                                <li className="scrollToLink"><a href="https://formandos.operaprodutora.com.br/" target="_blank" rel="noreferrer">Formandos</a></li>
                            </ul>
                        </div>
                        <div className="main-menu__right">
                            <a href="#" className="main-menu__toggler mobile-nav__toggler">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=5551997337941&text=Tenho%20interesse%20em%20um%20or%C3%A7amento:%0AFaculdade:%20%0ACurso:%0AQuantidade%20de%20formandos:%0AAno:%0ASemestre:%0A" target="_blank" rel="noreferrer" className="main-menu__phone">
                                <i className="icon-telephone" />
                                (51) 99733-7941
                            </a>
                        </div>
                    </div>
                </nav> 
            </header>
        )
}