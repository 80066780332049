import Topbar from '../components/topbar';
import MainHeader from '../components/mainheader';
import StrickyHeader from '../components/strickyheader';
import Slider from '../components/slider';
import MobileNav from '../components/mobilenav';
import MainFooter from '../components/mainfooter';

import Sobre from '../components/home/sobre';
import Chamadaorcamento from '../components/chamadaorcamento';
import Servicos from '../components/home/servicos'; 
import Portfolio from '../components/home/portfolio';
import Video from '../components/home/video';
import Realizacoes from '../components/home/realizacoes';
import Depoimentos from '../components/depoimentos';

function Home(){
   
    return (
    <>
      
      <div class="page-wrapper">
        <Topbar />
        <MainHeader />
        <StrickyHeader />
        <Slider />

        <Sobre />
        <Chamadaorcamento />
        <Servicos />
        <Portfolio />
        <Video />
        <Realizacoes />
        <Depoimentos />

      </div>

      <MobileNav /> 
      <MainFooter />
    </>
  );
}

export default Home;