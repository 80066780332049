import React from "react";

export default function Servicos(){
        return(
            <section className="portfolio-one">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className="section-title text-center">
                        <div className="section-title__triangle">
                        <span className="section-title__triangle-left" />
                        <span className="section-title__triangle-right" />
                        </div>
                        <h5 className="section-title__tagline">Sonhos realizados</h5>
                        <h2 className="section-title__title">Eventos recentes</h2>
                    </div>{/* section-title */}
                    </div>
                </div>
                <div className="portfolio-one__slider nisoz-owl__carousel owl-carousel" data-owl-options="{
                &quot;loop&quot;: true,
                &quot;items&quot;: 3,
                &quot;smartSpeed&quot;: 3000, 
                &quot;autoplay&quot;: true, 
                &quot;autoplayTimeout&quot;: 5000, 
                &quot;center&quot;: true,
                &quot;nav&quot;: false,
                &quot;dots&quot;: false,
                &quot;margin&quot;: 30,
                &quot;responsive&quot;:{
                    &quot;0&quot;:{
                        &quot;items&quot;: 1,
                        &quot;margin&quot;: 0
                    },
                    &quot;700&quot;:{
                        &quot;items&quot;: 1.5
                    },
                    &quot;500&quot;:{
                        &quot;items&quot;: 2
                    },
                    &quot;1300&quot;:{
                        &quot;items&quot;: 3
                    }
                }
                }">
                    <div className="item">{/* slider-item-start */}
                    <div className="portfolio-one__item">
                        <div className="portfolio-one__thumb">
                        <img src="assets/images/portfolio/portfolio-1-1.jpg" alt="nisoz" />
                        </div>
                        <div className="portfolio-one__hover">
                        <div className="portfolio-one__hover-bottom">
                            <div className="portfolio-one__cats"><a href="#">Engenharia Elétrica</a></div>
                            <h3 className="portfolio-one__title"><a href="#">UFRGS</a></h3>
                        </div>
                        </div>
                    </div>{/* folio-item */}
                    </div>{/* slider-item-end */}
                    <div className="item">{/* slider-item-start */}
                    <div className="portfolio-one__item">
                        <div className="portfolio-one__thumb">
                        <img src="assets/images/portfolio/portfolio-1-2.jpg" alt="nisoz" />
                        </div>
                        <div className="portfolio-one__hover">
                        <div className="portfolio-one__hover-bottom">
                            <div className="portfolio-one__cats"><a href="#">Agronomia</a></div>
                            <h3 className="portfolio-one__title"><a href="#">UFRGS</a></h3>
                        </div>
                        </div>
                    </div>{/* folio-item */}
                    </div>{/* slider-item-end */}
                    <div className="item">{/* slider-item-start */}
                    <div className="portfolio-one__item">
                        <div className="portfolio-one__thumb">
                        <img src="assets/images/portfolio/portfolio-1-3.jpg" alt="nisoz" />
                        </div>
                        <div className="portfolio-one__hover">
                        <div className="portfolio-one__hover-bottom">
                            <div className="portfolio-one__cats"><a href="#">Direito</a></div>
                            <h3 className="portfolio-one__title"><a href="#">PUC</a></h3>
                        </div>
                        </div>
                    </div>{/* folio-item */}
                    </div>{/* slider-item-end */}
                </div>{/* slider-end */}
                </div>
            </section>
        )
}


