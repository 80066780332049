import React from "react";
import Topbar from "../components/topbar";
import MainHeader from "../components/mainheader";
import StrickyHeader from "../components/strickyheader";
import MobileNav from "../components/mobilenav";
import PageHeader from "../components/pageheader";
import MainFooter from "../components/mainfooter";

export default function Orcamentos(){
    return(
        <>
        <div class="page-wrapper">
            <Topbar />
            <MainHeader />
            <StrickyHeader />

            <PageHeader titulo='Orçamentos' />

            <section className="contact-one contact-page">
            <div className="container wow fadeInUp animated" data-wow-delay="300ms" style={{"visibility":"visible","-webkit-animation-delay":"300ms","animation-delay":"300ms","-webkit-animation-name":"_fadeInUp","animation-name":"_fadeInUp"}}>
                <div className="section-title text-center">
                <div className="section-title__triangle">
                    <span className="section-title__triangle-left" />
                    <span className="section-title__triangle-right" />
                </div>
                <h5 className="section-title__tagline">Solicite uma proposta</h5>
                <h2 className="section-title__title">QUE TAL FAZER TEU EVENTO<br></br> COM QUEM ENTENDE<br /></h2>
                </div>{/* section-title */}
                <div className="contact-one__form-box  text-center">
                <form action="assets/inc/sendemail.php" className="contact-one__form contact-form-validated" noValidate="novalidate">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="contact-one__input-box">
                        <input type="text" placeholder="Seu nome" name="name" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contact-one__input-box">
                        <input type="email" placeholder="Email" name="email" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contact-one__input-box">
                        <input type="text" placeholder="Telefone" name="phone" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contact-one__input-box">
                        <div className="dropdown bootstrap-select"><select className="selectpicker" aria-label="Default select example">
                            <option selected>Selecione um serviço</option>
                            <option value={1}>Formatura</option>
                            <option value={2}>15 anos</option>
                            <option value={3}>Aniversário</option>
                            <option value={3}>Casamento</option>
                            </select><button type="button" tabIndex={-1} className="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="Selecione um serviço"><div className="filter-option"><div className="filter-option-inner"><div className="filter-option-inner-inner">Selecione um serviço</div></div> </div></button><div className="dropdown-menu "><div className="inner show" role="listbox" id="bs-select-1" tabIndex={-1}><ul className="dropdown-menu inner show" role="presentation" /></div></div></div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="contact-one__input-box text-message-box">
                        <textarea name="message" placeholder="Escreva uma  mensagem" defaultValue={""} />
                        </div>
                        <div className="contact-one__btn-box">
                        <button type="submit" className="nisoz-btn">
                            <span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" /><span className="nisoz-btn__shape" />
                            <span className="nisoz-btn__text">Solicitar  proposta</span>
                        </button>
                        </div>
                    </div>
                    </div>
                </form>
                <div className="result" />
                </div>
            </div>
            </section>

        </div>

        <MobileNav /> 
        <MainFooter />
        </>
    )
}